<template>
  <g-sales-counter action-hooks="salesCounter/update"/>
</template>

<script>
import GSalesCounter from "../../components/GSalesCounter";
export default {
  name: "EditSaleCounter",
  components: {GSalesCounter},
  metaInfo: {
    title: 'shantoitfirm.com',
    titleTemplate: 'Admin Panel - Edit Order | %s',
    meta: [
      {name: 'robots', content: 'noindex'},
      {name: 'googlebot', content: 'noindex'}
    ]
  }
}


</script>
