import PageProgress from "../../components/PageProgress";
import PageTitle from "../../components/PageTitle";
import GPAutoComplete from "../../components/GPAutoComplete";
import AddButton from "../../components/buttons/AddButton";
import Validation from "../../components/mixins/Validation";
import ConfirmPopup from "../../components/popups/ConfirmPopup";
import CancelButton from "../../components/buttons/CancelButton";
import ConfirmButton from "../../components/buttons/ConfirmButton";
import ViewLiveLink from "../../components/popups/ViewLiveLink";
import moment from "moment";
import ClientHandler from "../../services/Clients";
import Sites from "../../services/Sites";
import _ from "lodash";

export default {
    name: "SaleCounter",
    components: {
        ViewLiveLink,
        ConfirmButton,
        CancelButton,
        ConfirmPopup,
        AddButton,
        GPAutoComplete,
        PageTitle,
        PageProgress
    },
    mixins: [Validation],
    props: {
        actionHooks: {
            type: String,
            default: function (){
                return 'salesCounter/store'
            }
        }
    },
    data() {
        return {
            isSpecialClient: false,
            isMoreSpecialClient: false,
            isFixClient: false,
            isAgencyClient: false,
            isBuyer: false,
            isOverlay: false,
            isCheckedAdultPrice: false,
            isLoading: false,
            formValid: true,
            orderValid: true,
            showLiveLink: false,
            showItemDeleteConfirm: false,
            showOrderConfirm: false,
            showLinkInsertionPopup: false,
            percentageOfDiscount: 0,
            menu1: false,
            menu2: false,
            headers: [
                {
                    text: 'Site URL',
                    value: 'site_url'
                },
                {
                    text: 'Entity Name',
                    value: 'customEntityName'
                },
                {
                    text: 'Live Link',
                    value: 'liveLink'
                },
                {
                    text: 'Link Insertions',
                    value: 'linkInsertions'
                },
                {
                    text: 'Is allowed link insert?',
                    value: 'isAllowedLinkInsert'
                },
                {
                    text: 'Price',
                    value: 'price',
                    align: 'right'
                },
                {
                    text: 'Other Post Link Price',
                    value: 'adultPrice',
                    align: 'right'
                },
                {
                    text: 'Article Writing Price',
                    value: 'articleWritingPrice',
                    align: 'right'
                },
                {
                    text: 'Total',
                    value: 'total',
                    align: 'right'
                },
                {
                    text: 'Actions', value: 'actions', sortable: false
                }
            ],
            carts: [],
            currentProduct: {},
            product: {
                siteId: null,
                site_url: '',
                customEntityName:'',
                liveLink: '',
                isAllowedLinkInsert: true,
                quantity: 0,
                price: 0,
                articleWritingPrice: 0,
                linkPrice: 0,
                adultPrice: 0,
                total: 0
            },
            currentItem: null,
            deleteItem: null,
            order: {
                name: '',
                email: '',
                billingType: 'Guest Posting on',
                date: new Date().toISOString().substr(0, 10),
                linkExpiryDate: '',
                totalAmount: 0,
                discount: 0,
                invoiceDiscount: 0,
                amount: 0,
                publishableAmount: 0,
                isPrepayment: false,
                contactMail: ''
            },
            linkInsertions: [
                {
                    anchorText: '',
                    url: ''
                }
            ],
            isExists: false,
            fixClient: null,
            orderEmails: null,
            specialClientInnerIcon: 'mdi-shield-star',
            orderDueInfo: [],
            clientIcons: [
                {
                    type: 'specialClient',
                    icon: 'mdi-shield-star'
                },
                {
                    type: 'moreSpecialClient',
                    icon: 'mdi-star-three-points'
                },
                {
                    type: 'fixClient',
                    icon: 'mdi-shield-account-outline'
                },
                {
                    type: 'agencyClient',
                    icon: 'mdi-alpha-a-circle'
                },
                {
                    type: 'buyer',
                    icon: 'mdi-alpha-b-circle'
                },
            ],
            isAddBulkLink: false,
            bulkLiveLink: '',
            isBulkCheckedAdultPrice: false,
            bannedWords:[
                'casino',
                'gambling',
                'slot',
                'betting',
                'poker',
                'baccarat',
                'rummy',
                'jack-pot',
                'jackpot',
                'cbd',
                'vape',
                'marijuana',
            ],
        }
    },
    mounted() {
        if (!this.$route.params.id) return;
        this.isOverlay = true;
        this.$store.dispatch("salesCounter/get", {id: this.$route.params.id})
            .then(({data: {data: {item}}})=> {
                const {id, name, email, totalAmount,contactMail, billingType, sales_counter_details: details, discount, amount, date, publishableAmount, invoiceDiscount, isPrepayment} = item;
                 this.fixClientHandler(email).then();
                 this.orderEmails = {
                     id: contactMail,
                     name: contactMail
                 };

                this.order = {
                    id,
                    name,
                    email,
                    billingType: billingType ? billingType : 'Guest Posting on',
                    date: moment(date).format('YYYY-MM-DD'),
                    totalAmount,
                    discount,
                    invoiceDiscount,
                    amount,
                    publishableAmount,
                    isPrepayment,
                    contactMail
                };

                let _detail = details.filter((data)=> data.isActive === true);

                this.carts = _detail.map((item) => {
                    const {id, price, siteId, salesCounterId, quantity, liveLink, linkPrice, adultPrice, articleWritingPrice, site, customEntityName, total, isAllowedLinkInsert} = item;
                    const linkInsertions = this.getLinkInsertions(item)
                    return {
                        id,
                        site_url: site && site.site_url ? site.site_url:'',
                        customEntityName,
                        siteId,
                        salesCounterId,
                        quantity,
                        price,
                        liveLink,
                        linkPrice,
                        adultPrice,
                        total,
                        articleWritingPrice,
                        linkInsertions,
                        isAllowedLinkInsert
                    }
                })
                this.isOverlay = false;
            })
            .catch(() => {
                this.isOverlay = false;
                this.setSnackBar({text: "Data Not Found!", type: 'error'});
                this.$router.push('/sale-counters');
            })

    },
    computed: {
        subTotal() {
            let discount = 0;
            let subTotal = Number.parseFloat(
                this.carts.reduce((prev, item) => {
                    return Number.parseFloat(prev + Number.parseFloat(item.total));
                }, 0)
            ).toFixed(2);

            if(this.percentageOfDiscount > 0) {
                discount = (Number.parseFloat(subTotal) * (Number.parseFloat(this.percentageOfDiscount)/100)).toFixed(2);
            }

            this.order.discount = discount;

            return subTotal;
        },
        grandTotal() {
            return (this.subTotal - Number.parseFloat(this.order.discount)).toFixed(2)
        },
        totalPublishableAmount() {
            return Number.parseFloat(
                this.carts.reduce((prev, item) => {
                    const price = item.price ? Number.parseFloat(item.price) : 0
                    const linkPrice = item.linkPrice ? Number.parseFloat(item.linkPrice) : 0
                    const adultPrice = item.adultPrice ? Number.parseFloat(item.adultPrice) : 0
                    const total = Number.parseFloat(price + linkPrice + adultPrice)
                    return Number.parseFloat(prev + total);
                }, 0)
            ).toFixed(2);
        },
        showClientInnerIcon() {
            if(this.isSpecialClient) {
              return this.getClientIcon('specialClient');
            } else if(this.isFixClient) {
                return this.getClientIcon('fixClient');
            } else if(this.isAgencyClient) {
                return this.getClientIcon('agencyClient');
            } else if(this.isMoreSpecialClient) {
                return this.getClientIcon('moreSpecialClient');
            }else if(this.isBuyer) {
                return this.getClientIcon('buyer');
            } else {
                return  '';
            }
        },

    },
    methods: {
        getClientIcon(type) {
            let client = this.clientIcons.find((item) => item.type === type);
            if(client) {
                return client.icon;
            }

            return '';
        },
        onSelectionsOrderEmail(item) {
            this.orderEmails = {...item};
            this.order.contactMail = item.name;
        },
       async onSelections(item) {
            this.currentProduct = {...item};
            let {id, site_url, reseller_price: price, adultPrice} = item;
            let _adultPrice = 0;

            if(this.fixClient) {
                adultPrice = 0;
                this.currentProduct.adultPrice = '0.00';
                price = this.getFixClientPrice();
            }

            let total = price;
            if(this.isCheckedAdultPrice) {
                _adultPrice = Number(adultPrice);
                total = Number(adultPrice) + Number(price)
            }

            this.product = {
                ...this.product,
                siteId: id,
                site_url: site_url,
                price,
                quantity: 1,
                adultPrice: _adultPrice,
                total
            }

           await this.checkLiveLinks();
        },
        getFixClientPrice() {
          const {price, linkPrice, adultPrice} = this.fixClient;
          const billingType = this.order.billingType;
          this.currentProduct.adultPrice = '0.00';

          if(this.isCheckedAdultPrice) {
              return Number.parseFloat(adultPrice);
          } else if(billingType === 'Link Insertion on' || billingType === 'Home Page Link Insertion on') {
              this.product.articleWritingPrice = 0;
              return Number.parseFloat(linkPrice);
          } else {
              return Number.parseFloat(price);
          }
        },
        fixClientBillingTypeHandler() {
           if(!this.fixClient || !this.product.siteId) return;

           let price = this.getFixClientPrice();

           Object.assign(this.product, {
               ...this.product,
               price,
               linkPrice: 0,
               adultPrice: 0,
               total: Number.parseFloat(this.product.articleWritingPrice) + price
           })
        },
        inputPriceHandler() {
            const {price, adultPrice, articleWritingPrice} = this.product;

            this.product.total = (Number(price) + Number(articleWritingPrice) + Number(adultPrice)).toFixed(2);
        },
        onCheckboxAdultHandler() {
            let adultPrice = Number(this.currentProduct.adultPrice);

            if(this.fixClient) {
                adultPrice = 0;
                this.currentProduct.adultPrice = '0.00';
                this.fixClientBillingTypeHandler();
            }

            if(this.isCheckedAdultPrice) {
                this.product.adultPrice = adultPrice;
                this.product.total = Number(this.product.total) + adultPrice;
            } else {
                this.product.adultPrice = 0;
                this.product.total = Number(this.product.total) - adultPrice;
            }
        },
        handleClearSearchResult() {
            this.resetProductHandler();
        },
        handleClearOrderEmailSearchResult() {
            this.order.contactMail = '';
            this.orderEmails = null;
        },
        resetProductHandler() {
            this.product = {
                ...this.product,
                site: null,
                siteId: null,
                site_url: '',
                price: 0,
                quantity: 1,
                total: 0,
                adultPrice: 0,
                linkPrice: 0,
                isAllowedLinkInsert: true,
                articleWritingPrice: 0,
                liveLink: ''
            };
            this.currentProduct = {};
            this.linkInsertions = [
                {
                    anchorText: '',
                    url: ''
                }
            ]
            this.isCheckedAdultPrice = false;
        },
        async addToCartHandler() {
            if (!this.$refs.cartFormRef.validate()) return;
            const id = Math.floor((Math.random() * 1000) + 1);
            const billingType = this.order.billingType;
            const liveLink = this.product.liveLink;

            if(billingType === 'Link Insertion on') {
               const {data: {data}} = await this.$store.dispatch('linkInsertion/get', {liveLink});
               if(data) {
                   this.setSnackBar({text: 'This link is not allowed!', type: 'error'});
                   return ;
               }
            }

            if(this.order.id){
                this.carts.push({...this.product, id, salesCounterId: this.order.id, linkInsertions: _.cloneDeep(this.linkInsertions)});
            } else {
                this.carts.push({...this.product, id, linkInsertions: _.cloneDeep(this.linkInsertions)});
            }

            this.$refs.cartFormRef.reset();
            this.$nextTick(() => {
                this.resetProductHandler();
            })
        },
        onHandleLinkEditable(items){
            const {id, link} = items;
            const index = this.carts.findIndex((item) => item.id === id);
            if(index > -1) {
                this.carts[index].liveLink = link;
            }
        },
        liveLinkHandler(item) {
            this.showLiveLink = true;
            this.currentItem = item;
        },
        closePopupHandler() {
            this.showLiveLink = false;
            this.showLinkInsertionPopup = false;
            this.currentItem = null;
        },
        confirmToDeleteItem(item) {
            this.showItemDeleteConfirm = true;
            this.deleteItem = item;
        },
        getAmountWithSymbol(amount) {
            const _amount = Number.parseFloat(amount).toFixed(2);
            return `$${_amount}`;
        },
        confirmDelete() {
            const id = this.deleteItem.id;
            const index = this.carts.findIndex((item) => item.id === id);

            if (index > -1) {
                this.carts.splice(index, 1);
            }

            this.showItemDeleteConfirm = false;
            this.deleteItem = null;
        },
        /*onPriceHandler(id, price) {
            const index = this.carts.findIndex((item) => item.id === id);

            if (index > -1) {
                this.carts[index].price = price;
                this.carts[index].total = Number.parseFloat(price) + Number.parseFloat(this.carts[index].adultPrice) + Number.parseFloat(this.carts[index].articleWritingPrice);

                this.carts = [...this.carts];
            }
        },*/
        onCancelInvoice() {
            if (this.$route.params.id) {
                this.$router.push({path:'/sale-counters'});
                return;
            }
            this.resetForm();
            this.carts = []
        },
        resetForm() {
            this.$refs.cartFormRef.reset();
            this.$refs.orderFormRef.reset();
            this.handleClearOrderEmailSearchResult();
            this.$nextTick(() => {
                this.orderEmails = null;
                this.isSpecialClient = false;
                this.isBuyer = false;
                this.orderDueInfo = [];
                this.order = {...this.order, contactMail: '', email: '', billingType: 'Guest Posting on', totalAmount: 0, amount: 0, discount: 0, invoiceDiscount: 0, publishableAmount: 0, isPrepayment: false, date: new Date().toISOString().substr(0, 10), linkExpiryDate: ''};
                this.resetProductHandler();
            })
        },
        async onConfirmHandler(confirmed = false) {
            if (!this.$refs.orderFormRef.validate() || !this.$refs.cartFooterForm.validate()) return;

            if (!confirmed) {
                this.showOrderConfirm = true;
                return;
            }

            this.showOrderConfirm = false;

            this.$store.dispatch(this.actionHooks, {...this.order, amount: this.subTotal, totalAmount: this.grandTotal, carts: this.carts, publishableAmount: this.totalPublishableAmount})
                .then((response) => {
                    this.setSnackBar({text: response.data.message, type: 'info'});
                    if(this.actionHooks !== 'salesCounter/update') {
                        this.carts = [];
                        this.fixClient = null;
                        this.resetForm();
                    }
                })
                .catch((error) => {
                    this.setSnackBar({text: error.response ? error.response.data.message: 'Validation error!!', type: 'error'});
                })

            if(this.actionHooks === 'salesCounter/update') {
                await this.$router.push({name: 'SaleCounters', path: '/sale-counters'});
            }
        },
        setSnackBar({text, type}) {
            this.$store.commit('SET_SNACKBAR', {text, type});
        },
        async getBySiteUrl(url) {
            return Sites
                .getByOne({site_url: url})
                .then((response) => response.data);
        },
        async checkLiveLinks() {
            let site_url = this.product.site_url;
            const linkArr = this.product.liveLink ? this.product.liveLink.split("/") : '';
            let text = _.lowerCase(this.product.liveLink);
            let isBannedWord = _.some(this.bannedWords, (el)=> text.includes(el));
            if(isBannedWord) {
                this.setSnackBar({text: `The URL ${this.product.liveLink} has words like: (${this.bannedWords.join(', ')}) must be removed.`, type: 'error'});
                return;
            }

            if(linkArr.length < 2) return;

            const linkSite = linkArr[2] ? linkArr[2] : '';

            if (!site_url) {
                this.isOverlay = true;
                try {
                    const {data: {item}} = await this.getBySiteUrl(linkSite);

                    if(!item) {
                        this.checkLiveLinkMessageHandler(site_url, linkSite);
                        this.isOverlay = false;
                        return;
                    }

                    site_url = item.site_url;
                    this.product.site_url = site_url;
                    this.product.site = {...item, site_url, reseller_price: item.reseller_price, adultPrice: item.adultPrice};
                    await this.onSelections(this.product.site);

                    if(linkSite !== site_url) {
                        this.product.liveLink = '';
                        this.checkLiveLinkMessageHandler(site_url, linkSite)
                    }

                    this.$nextTick(() => {
                        this.product = {...this.product};
                        this.isOverlay = false;
                    });
                } catch (error) {
                    let message = `Site not found!!`;
                    if(error.response && error.response.data){
                        message = error.response.data.message;
                    }
                    this.setSnackBar({text: message, type: 'error'});
                    this.isOverlay = false;
                }
            }

        },
        checkLiveLinkMessageHandler(site_url, linkSite) {
            this.product.liveLink = '';
            this.setSnackBar({text: `You are selected this ${site_url} and your live link from ${linkSite} does not matched each other. Please check your live link`, type: 'error'});
        },
        linkInsertionRowHandler() {
            if (this.linkInsertions.length >= 10) return;
            this.linkInsertions.push({anchorText: '', url: ''});
        },
        linkInsertionRowRemoveHandler(index) {
            this.linkInsertions.splice(index, 1)
        },
        linkInsertionPopupHandler(item) {
            this.showLinkInsertionPopup = true;
            this.currentItem = item;
        },
        updateLinkInsertionHandler(item) {
            const index = this.carts.findIndex(data => data.id = item.productId);

            if(index > -1) {
                this.carts[index].linkInsertions = item.linkInsertions;
            }
            this.closePopupHandler();
        },
        getLinkInsertions(item) {
            const {anchorText1, url1, anchorText2, url2, anchorText3, url3, anchorText4, url4, anchorText5, url5, anchorText6, url6, anchorText7, url7, anchorText8, url8, anchorText9, url9,anchorText10, url10} = item;

            let arr = []

            if(anchorText1) {
               arr.push({anchorText: anchorText1, url: url1})
            }

            if(anchorText2) {
                arr.push({anchorText: anchorText2, url: url2})
            }

            if(anchorText3) {
                arr.push({anchorText: anchorText3, url: url3})
            }

            if(anchorText4) {
                arr.push({anchorText: anchorText4, url: url4})
            }

            if(anchorText5) {
                arr.push({anchorText: anchorText5, url: url5})
            }

            if(anchorText6) {
                arr.push({anchorText: anchorText6, url: url6})
            }

            if(anchorText7) {
                arr.push({anchorText: anchorText7, url: url7})
            }

            if(anchorText8) {
                arr.push({anchorText: anchorText8, url: url8})
            }

            if(anchorText9) {
                arr.push({anchorText: anchorText9, url: url9})
            }

            if(anchorText10) {
                arr.push({anchorText: anchorText10, url: url10})
            }

            return arr;
        },
       async onSelectionsEmailHandler(selection) {
            const email = selection.email;
            this.order.email = selection.email;
            await this.fixClientHandler(email);
        },
        handleClearSearchResultEmail() {
            this.isSpecialClient = false;
            this.order.email = '';
        },
        async fixClientHandler(email) {
            this.percentageOfDiscount = 0;
            this.orderDueInfo = [];
            this.fixClient = null;
            if(!email) return;
            this.isOverlay = true;
            this.isFixClient = false;
            this.isSpecialClient = false;
            this.isAgencyClient = false;
            this.isBuyer = false;

            try {
               const {data: {data}} = await ClientHandler.get({email});
               let {dueData, clientInfo} = data
               this.orderDueInfo =  dueData;

               if(!clientInfo) {
                   this.isOverlay = false;
                   return;
               }

               if(clientInfo.type === 3) {
                   this.isSpecialClient = true;
                   this.isOverlay = false;
                   this.percentageOfDiscount = clientInfo.discount;
                   return ;
               } else if(clientInfo.type === 5) {
                   this.isAgencyClient = true;
                   this.isOverlay = false;
                   this.percentageOfDiscount = clientInfo.discount;
                   return ;
               } else if(clientInfo.type === 4) {
                   this.isMoreSpecialClient = true;
                   this.isOverlay = false;
                   this.percentageOfDiscount = clientInfo.discount;
                   return ;
               } else if(clientInfo.type === 6) {
                   this.isBuyer = true;
                   this.isOverlay = false;
                   this.percentageOfDiscount = clientInfo.discount;
                   return;
               } else {
                   this.isFixClient = true;
                   this.percentageOfDiscount = clientInfo.discount;
               }


               this.fixClient = clientInfo;

               let price = this.getFixClientPrice();

               if(this.product.siteId){
                   this.product.price = price;
                   this.inputPriceHandler();
               }

               if(this.carts.length > 0) {
                 this.carts =  this.carts.map((item) => {
                       let articleWritingPrice = this.product.articleWritingPrice;
                        return {
                            ...item,
                            price,
                            linkPrice: 0,
                            adultPrice: 0,
                            total: (Number(price) + Number(articleWritingPrice)).toFixed(2)
                        }
                   })
               }
               this.isOverlay = false;
            } catch(error) {
                this.isOverlay = false;
            }
        },
        saveOrderExpiryDate (date) {
            this.$refs.menu2.save(date)
        },
        async bulkLiveLinkHandler(event) {
            if(!event) return;
            this.isOverlay = true;
            let links = event.split("\n");
             links = _.filter(links);

            let sites = links.map((item) => {
                let siteLink = this.getSiteUrl(item);
                if(siteLink) {
                    return siteLink
                }
            })

            sites = _.uniq(sites);

           const {data: {data: {items}}} = await Sites.getBulkSite({sites: sites});

           links.forEach((item) => {
               const id = Math.floor((Math.random() * 1000) + 1);
               let siteLink = this.getSiteUrl(item);
               let site = items.find((data) => data.site_url === siteLink);
               if(site) {
                   let price = site.reseller_price;
                   let adultPrice = site.adultPrice;
                   let total = price;
                   let product = {
                       siteId: site.id,
                       site_url: site.site_url,
                       customEntityName:'',
                       liveLink: item,
                       isAllowedLinkInsert: true,
                       quantity: 1,
                       price: price,
                       articleWritingPrice: 0,
                       linkPrice: 0,
                       adultPrice: 0,
                       total: total
                   };
                   if(this.isBulkCheckedAdultPrice) {
                       product.adultPrice = adultPrice;
                       product.total = (Number(price) + Number(adultPrice)).toFixed(2);
                   }

                   if(this.order.id){
                       this.carts.push({...product, id, salesCounterId: this.order.id, linkInsertions: _.cloneDeep(this.linkInsertions)});
                   } else {
                       this.carts.push({...product, id, linkInsertions: _.cloneDeep(this.linkInsertions)});
                   }
               }
           });

            this.bulkLiveLink = ''
            this.isBulkCheckedAdultPrice = false;
            this.isOverlay = false;
        },
        getSiteUrl(item) {
            const linkArr = item.split("/");
            if(linkArr.length > 2) {
                return  linkArr[2] ? linkArr[2] : '';
            }
            return '';
        }
    }
}